import * as yup from "yup";

// Define the phone number schema using Yup
export const PhoneNumberSchema = yup.object({
  phoneType: yup.string().required(),
  countryCode: yup.string().when("phoneType", {
    is: (val: string) => val === "US",
    then: yup.string().matches(/^1$/),
    otherwise: yup.string().matches(/^(?!1$)\d{1,4}$/, "Country code must be between 1 and 4 digits and cannot be '1'   "),
  }),
  phoneNumber: yup
    .string()
    .transform((value) => value.replace(/[\s()-]/g, ""))
    .when("phoneType", {
      is: (val: string) => val === "US",
      then: yup.string().matches(/^\d{10}$/, "US phone numbers must be 10 digits "),
      otherwise: yup.string().matches(/^\d{7,20}$/, "International phone numbers must be between 7 and 20 digits"),
    }),
});

// Infer TypeScript type from Yup schema
export type PhoneNumberFields = yup.InferType<typeof PhoneNumberSchema>;
