import InputField from "@components/FormControls/CatalystTextField";

import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterSchema, registerSchema } from "@validators/auth";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "@contexts/AuthContext";
import toast from "react-hot-toast";
import { register as signUp } from "@contexts/AuthContext/functions/register";
import { setRegistrationData } from "@contexts/AuthContext/functions/setRegistrationData";
import { Fieldset, FieldGroup, Label } from "@components/Catalyst/fieldset";
import { Text } from "@components/Catalyst/text";
import { Button } from "@components/Catalyst/button";
import { Checkbox, CheckboxField } from "@components/Catalyst/checkbox";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid";

const SmallRegisterForm = ({ onCompletion }: { onCompletion?: (email: string) => void }) => {
  const [agreement, setAgreement] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<RegisterSchema>({ resolver: yupResolver(registerSchema), mode: "onChange" });
  const { getCognitoUserPool } = useAuth();

  const onSubmit = async (data: any) => {
    const executeSignUp = async () => {
      const cognitoUserPool = getCognitoUserPool();

      signUp(cognitoUserPool, data, setRegistrationData);
      if (onCompletion) {
        onCompletion(data.email);
      }
    };

    toast.promise(executeSignUp(), {
      loading: "Loading...",
      success: `A verification code has been sent to your email. Please enter it below to confirm your registration.`,
      error: (error) => error.message,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset>
        <FieldGroup>
          <div className="flex flex-col md:grid md:grid-cols-2 md:place-items-end md:gap-4">
            <InputField
              className="w-full"
              required
              label="Name"
              register={register}
              name="firstName"
              error={errors.firstName}
              placeholder="First"
            />
            <InputField className="w-full" required placeholder="Last" register={register} name="lastName" error={errors.lastName} />
          </div>

          <div className="flex flex-col md:grid md:grid-cols-2 md:place-items-end md:gap-4">
            <InputField className="w-full" required label="Email" register={register} name="email" error={errors.email} />
            <InputField
              className="w-full"
              required
              register={register}
              name="confirmEmail"
              error={errors.confirmEmail}
              placeholder="Confirm email"
            />
          </div>

          <div className="flex flex-col md:grid md:grid-cols-2 md:place-items-end md:gap-4">
            <InputField
              className="w-full"
              type="password"
              required
              label="Password"
              register={register}
              name="password"
              error={errors.password}
              autocomplete="new-password"
            />
            <InputField
              className="w-full"
              required
              type="password"
              register={register}
              name="passwordConfirmation"
              error={errors.passwordConfirmation}
              placeholder="Confirm password"
              autocomplete="new-password"
            />
          </div>
          <Text>
            Password must be at least 8 characters in length and include a combination of upper and lowercase letters, numbers, and symbols
          </Text>

          <div>
            <CheckboxField>
              <Checkbox name="agree_to_terms" onChange={() => setAgreement(!agreement)} />
              <Label>I have reviewed and understand the Privacy Policy</Label>
            </CheckboxField>
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              <div className="flex items-center text-sm text-cyan-700 underline">
                Privacy Policy
                <ArrowTopRightOnSquareIcon className="inline h-4 w-4 stroke-cyan-700" />
              </div>
            </a>
          </div>

          <div className="flex gap-2">
            <Button type="submit" disabled={isSubmitting || !isValid || !agreement}>
              Submit
            </Button>

            {!isValid && <Text className="content-center">Please ensure all fields are filled and errors are fixed.</Text>}
          </div>
        </FieldGroup>
      </Fieldset>
    </form>
  );
};

export default SmallRegisterForm;
