import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";

export const getInternalToken = (cognitoUser: CognitoUser): Promise<{ token: string; expire: number } | null> =>
  new Promise((resolve, reject) => {
    if (!cognitoUser) {
      resolve(null);
      return;
    }

    cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
      if (err) {
        cognitoUser.signOut();
        reject(err);
        return;
      }

      const accessToken = session.getIdToken();
      const expiration = accessToken.getExpiration() * 1000;
      const currentTime = Date.now();

      // Check if the token is about to expire (e.g., in the next 5 minutes)
      if (expiration - currentTime < 5 * 60 * 1000) {
        console.log("Token is about to expire. Attempting to refresh...");

        cognitoUser.refreshSession(session.getRefreshToken(), (refreshErr, refreshedSession) => {
          if (refreshErr) {
            console.error("Failed to refresh session:", refreshErr);
            cognitoUser.signOut();
            reject(refreshErr);
            return;
          }

          const refreshedAccessToken = refreshedSession.getIdToken();
          const refreshedToken = refreshedAccessToken.getJwtToken();
          resolve({ token: refreshedToken, expire: refreshedAccessToken.getExpiration() * 1000 });
        });
      } else {
        // Token is still valid, return it
        const token = accessToken.getJwtToken();
        resolve({ token, expire: expiration });
      }
    });
  });
