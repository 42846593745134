import { z } from "zod";
import { PaintMixOutputSchema } from "./mix";
import { PaletteOutputSchmea } from "./palette";
import { EventRegistrationOutputSchema } from "./eventRegistration";
import { AddressOutputSchema } from "./address";

export const RegisterInputSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
});

export type RegisterInputType = z.infer<typeof RegisterInputSchema>;

export const GenCheckinOutputSchema = z.object({
  checkinType: z.string(),
  checkinDate: z.date(),
});

export type GenCheckinOutputType = z.infer<typeof GenCheckinOutputSchema>;
export const UpdateProfileInputSchema = z
  .object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    website: z.string().optional(),
    phoneNumber: z
      .string()
      .optional()
      .refine((val) => !val || /^\d+$/.test(val), {
        message: "Phone number must contain only numbers",
      }),
    phoneNumberCC: z
      .string()
      .optional()
      .refine((val) => !val || /^\d+$/.test(val), {
        message: "Country code must contain only numbers",
      }),
    facebookLink: z.string().optional(),
    linkedInLink: z.string().optional(),
    instagramLink: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.phoneNumber && !data.phoneNumberCC) {
      ctx.addIssue({
        path: ["phoneNumberCC"],
        message: "Phone number country code is required when phone number is provided",
        code: "custom",
      });
    }
  });
export type UpdateProfileInputType = z.infer<typeof UpdateProfileInputSchema>;

export const UserOutputSchema = z.object({
  id: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string(),
  website: z.string().optional(),
  addresses: z.array(AddressOutputSchema),
  phoneNumber: z.string().optional(),
  phoneNumberCC: z.string().optional(),
  instagramLink: z.string().optional(),
  linkedInLink: z.string().optional(),
  facebookLink: z.string().optional(),
  publicId: z.string().optional(),
  publicName: z.string().optional(),
  mixes: z.array(PaintMixOutputSchema),
  palettes: z.array(PaletteOutputSchmea),
  events: z.array(EventRegistrationOutputSchema),
  checkins: z.array(GenCheckinOutputSchema),
});

export type UserOutputType = z.infer<typeof UserOutputSchema>;
