import { z } from 'zod';
import { BrandCodeSchema } from './mix';

export const PaletteInputSchema = z.object({
	id: z.string().optional(),
	paletteName: z.string(),
	colors: z.array(z.string()).nullable(),
	brandCode: BrandCodeSchema,
});

export type PaletteInputType = z.infer<typeof PaletteInputSchema>;

export const PaletteOutputSchmea = z.object({
	id: z.string(),
	paletteName: z.string(),
	colors: z.array(z.string()),
	brandCode: BrandCodeSchema,
});

export type PaletteOutputType = z.infer<typeof PaletteOutputSchmea>;
