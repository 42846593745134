import { z } from "zod";

export const AddressOutputSchema = z.object({
  id: z.string(),
  addressName: z.string(), // Home, Work, etc
  streetName: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  country: z.string(),
  placeId: z.string(),
  lat: z.number().min(-90).max(90),
  lng: z.number().min(-180).max(180),
  geohash: z.string(),
});

export type AddressOutputType = z.infer<typeof AddressOutputSchema>;

export const AddressInputSchema = z.object({
  addressName: z.string(),
  streetName: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  country: z.string(),
  placeId: z.string(),
  lat: z.number(),
  lng: z.number(),
  geohash: z.string(),
});

export type AddressInputType = z.infer<typeof AddressInputSchema>;
