import { IUser } from "@typed/User";
import { UserOutputSchema } from "../schema/user";

export const GetUser = async (id: string, getAuthToken: () => Promise<string | null>) => {
  const authToken = await getAuthToken();
  if (!authToken) throw new Error("Couldn't get Auth Token. Make sure you're logged in.");

  // Send the product replacement request
  const response = await fetch(`${process.env.GATSBY_GAC_API2_URI}/user/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Request failed with status: ${response.status} - ${response.statusText}. Server response: ${errorText}`);
  }

  const user: IUser = UserOutputSchema.parse(await response.json());

  return user;
};
