import React, { useState } from "react";
import { Dialog, DialogBody, DialogDescription, DialogTitle } from "@components/Catalyst/dialog";
import { Navbar, NavbarItem, NavbarSection } from "@components/Catalyst/navbar";

import LoginForm from "@components/Forms/LoginForm";
import { useAuth } from "@contexts/AuthContext";
import SmallRegisterForm from "@components/Forms/RegisterForm/small";
import SmallForgotPasswordForm from "@components/Forms/ForgotPasswordForm/small";
import SmallResetPasswordForm from "@components/Forms/ResetPasswordForm/small";
import SmallConfirmRegistrationForm from "@components/Forms/ConfirmRegistration/small";
import { Button } from "@components/Catalyst/button";

const LoginModal = ({
  isOpen,
  setIsOpen,
  focusTab = "login",
  setFocusTab,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  focusTab: "login" | "new-account" | "forgot-password";
  setFocusTab: (value: "login" | "new-account" | "forgot-password") => void;
}) => {
  const { gacUser, logout } = useAuth();

  const [newUserStep, setNewUserStep] = useState("data-entry");
  const [loginEmail, setLoginEmail] = useState("");

  const [forotPassStep, setForgotPassStep] = useState("collect-email");

  if (gacUser) {
    return (
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle className="flex">
          <img className="w-7" src="/images/logos/Stud_grn.svg" alt="Studio Assistant" />
          Studio Assistant Login
        </DialogTitle>
        <DialogBody>
          <div className="flex flex-col gap-4">
            Logged in as {gacUser.email}
            <div>
              <Button onClick={() => logout()}>Logout</Button>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onClose={setIsOpen}>
      <DialogTitle className="flex">
        <img className="w-7" src="/images/logos/Stud_grn.svg" alt="Studio Assistant" />
        Studio Assistant Login
      </DialogTitle>
      <DialogDescription>
        Studio Assistant provides you with full access to all the digital tools offered by Golden Artist Colors.
      </DialogDescription>
      <DialogBody>
        <Navbar>
          <NavbarSection>
            <NavbarItem current={focusTab === "login"} onClick={() => setFocusTab("login")}>
              Login
            </NavbarItem>
            <NavbarItem current={focusTab === "new-account"} onClick={() => setFocusTab("new-account")}>
              Create New Account
            </NavbarItem>
            <NavbarItem current={focusTab === "forgot-password"} onClick={() => setFocusTab("forgot-password")}>
              Forgot Password
            </NavbarItem>
          </NavbarSection>
        </Navbar>

        <div className="flex flex-col space-y-4 pl-2 pt-6">
          {focusTab === "login" && (
            <LoginForm
              email={loginEmail}
              closeMenu={() => {
                setIsOpen(false);
              }}
            />
          )}
          {focusTab === "new-account" && newUserStep === "data-entry" && (
            <>
              <SmallRegisterForm
                onCompletion={(e) => {
                  setLoginEmail(e);
                  setNewUserStep("confirm-email");
                }}
              />

              <Button plain onClick={() => setNewUserStep("confirm-email")}>
                <div className="flex items-center text-sm text-cyan-700 underline">Already have a code? Click to enter here.</div>
              </Button>
            </>
          )}
          {focusTab === "new-account" && newUserStep === "confirm-email" && (
            <>
              <SmallConfirmRegistrationForm
                email={loginEmail}
                onCompletion={() => {
                  setNewUserStep("data-entry");
                  setFocusTab("login");
                }}
              />

              <Button plain onClick={() => setNewUserStep("data-entry")}>
                <div className="flex items-center text-sm text-cyan-700 underline">Create an account.</div>
              </Button>
            </>
          )}

          {focusTab === "forgot-password" && forotPassStep === "collect-email" && (
            <SmallForgotPasswordForm
              onCompletion={(e) => {
                setLoginEmail(e);
                setForgotPassStep("reset-pass");
              }}
            />
          )}
          {focusTab === "forgot-password" && forotPassStep === "reset-pass" && (
            <SmallResetPasswordForm
              email={loginEmail}
              onCompletion={() => {
                setFocusTab("login");
                setForgotPassStep("collect-email");
              }}
            />
          )}
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default LoginModal;
