import React, { ReactElement, createContext, useContext, useMemo, useState, useEffect } from "react";
import { CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import LoginModal from "@components/UserModals/LoginModal";
import { GetUser } from "@services/GACApi2/user/get";
import { RegisterUser } from "@services/GACApi2/user/register";
import { IUser, RegistrationData } from "@typed/User";

import { useSyncedSessionStorage } from "@contexts/useSyncedSessionStorage";
import { setRegistrationData } from "./functions/setRegistrationData";
import { getRegistrationData } from "./functions/getRegistrationData";
import { getInternalToken } from "./functions/getInternalToken";
import { getEmailFromCurrentUser } from "./functions/getEmailFromCurrentUser";

import { login as cognitoLogin } from "./functions/login";
import { logout as cognitoLogout } from "./functions/logout";

type AuthContextType = {
  getRegistrationData: () => RegistrationData | null;
  getCognitoUserPool: () => CognitoUserPool;
  getCognitoUser: () => CognitoUser | null;

  setGacUser: (user: IUser | null) => void;
  gacUser: IUser | null;
  getAuthToken: () => Promise<string | null>;

  getUserRoles: () => Promise<string[] | null>;
  login: (email: string, password: string) => Promise<IUser | null>;
  logout: () => Promise<void>;
  loadUser: () => Promise<IUser | null>;
  ShowLoginModal: (tab: "login" | "new-account" | "forgot-password") => void;
  showingLoginModal: boolean;
};

const AuthContext = createContext<AuthContextType>({
  getRegistrationData: () => null,
  getCognitoUser: () => null,

  getCognitoUserPool: () => ({}) as CognitoUserPool,

  setGacUser: () => null,
  gacUser: null,
  getAuthToken: async () => null,
  getUserRoles: async () => null,
  login: async () => null,
  logout: async () => Promise.resolve(),
  loadUser: async () => null,
  ShowLoginModal: (_tab) => null,
  showingLoginModal: false,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactElement | ReactElement[] }) => {
  // Using useSyncedSessionStorage to store the user in session storage ... allows us to cache user through teh system, but still get login updates
  const [gacUser, setGacUserInternal, clearGacUser] = useSyncedSessionStorage<IUser | null>("GACUSer", null);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [tokenExpire, setTokenExpire] = useState<number | null>(null);
  const [userRoles, setUserRoles, clearGacRoles] = useSyncedSessionStorage<string[] | undefined>("GACUserRoles", undefined);

  const [loginOpen, setLoginOpen] = useState(false);
  const [activeLoginModalTab, setActiveLoginModalTab] = useState<"login" | "new-account" | "forgot-password">("login");

  const initializeGacUserPool = () => {
    if (!process.env.GATSBY_AWS_USER_CLIENT_ID) {
      throw new Error("Missing key in env: GATSBY_AWS_USER_CLIENT_ID");
    }
    if (!process.env.GATSBY_AWS_USER_POOL_ID) {
      throw new Error("Missing key in env: GATSBY_AWS_USER_POOL_ID");
    }

    return new CognitoUserPool({
      UserPoolId: process.env.GATSBY_AWS_USER_POOL_ID,
      ClientId: process.env.GATSBY_AWS_USER_CLIENT_ID,
    });
  };

  const getCognitoUserPool = () => initializeGacUserPool();

  const getCognitoUser = () => initializeGacUserPool().getCurrentUser();

  const getUserRoles = async () => {
    // short circuit ... if user not logged in , return empty
    if (!gacUser) return [];

    // short circuit ... if userRoles already loaded, return it.
    if (userRoles) {
      return userRoles;
    }

    const cognitoUser = getCognitoUser();

    if (!cognitoUser) {
      return [];
    }

    await new Promise<CognitoUserSession>((resolve, reject) => {
      cognitoUser.getSession((error: Error | null, session: CognitoUserSession) => {
        if (error) {
          reject(error);
          return;
        }

        resolve(session);
      });
    });

    return new Promise<string[]>((resolve, reject) => {
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
          return;
        }

        if (!attributes) {
          reject(new Error("No attributes found"));
          return;
        }

        const roles = attributes.filter((attr) => attr.Name.startsWith("custom:is")).map((attr) => attr.Name);
        resolve(roles);
      });
    })
      .then((roles) => {
        setUserRoles(roles);
        return roles;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  };

  const setGacUser = (user: IUser | null) => {
    setGacUserInternal(user);
    // if (!user && typeof window !== "undefined") {
    //   sessionStorage.removeItem("GACUser");
    //   return;
    // }

    // if (typeof window !== "undefined") sessionStorage.setItem("GACUser", JSON.stringify(user));
  };

  const getAuthToken = async () => {
    if (authToken && tokenExpire && Date.now() < tokenExpire - 5 * 60 * 1000) return authToken;

    const cognitoUser = getCognitoUser();
    if (!cognitoUser) {
      setAuthToken(null);
      setTokenExpire(null);
      return null;
    }

    const token = await getInternalToken(cognitoUser);
    if (!token) {
      setAuthToken(null);
      setTokenExpire(null);
      return null;
    }

    setAuthToken(token.token);
    setTokenExpire(token.expire);

    return token.token;
  };

  const logout = async () => {
    setGacUser(null);
    setAuthToken(null);
    setTokenExpire(null);
    setUserRoles(undefined);

    clearGacUser();
    clearGacRoles();

    const cognitoUser = getCognitoUser();

    await cognitoLogout(cognitoUser);
  };

  const loadUser = async (): Promise<IUser | null> => {
    if (gacUser) return gacUser;

    const authtoken = await getAuthToken();
    const cognitoUser = getCognitoUser();

    // couldnt get authToken from cognito, user not logged in, ensure user is clear
    if (!authtoken || !cognitoUser) {
      setGacUser(null);
      return null;
    }

    const storedUser = typeof window !== "undefined" ? sessionStorage.getItem("GACUser") : null;

    // if user is stored in session, use that
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setGacUser(user);
      return user;
    }

    const registrationData = getRegistrationData();
    // if user is not stored in session, and no registration data, load user from db
    if (!registrationData) {
      const user = await GetUser(await cognitoUser.getUsername(), getAuthToken);

      setGacUser(user);
      return user;
    }

    const tokenEmail = await getEmailFromCurrentUser(cognitoUser);

    // if registration data exists, update user with registration data
    if (registrationData && registrationData.email === tokenEmail) {
      const tempUser = await RegisterUser(getAuthToken, registrationData);
      setGacUser(tempUser);

      setRegistrationData(null);
      return tempUser;
    }

    // If registration data exists but for different email, skip registration data update, and just load the user from db
    // This would rarely happen.
    try {
      const tempUser = await GetUser(await cognitoUser.getUsername(), getAuthToken);

      setGacUser(tempUser);
      return tempUser;
    } catch (error) {
      console.error("Error loading user", error);
      await logout();
      return null;
    }
  };

  const login = async (email: string, password: string): Promise<IUser | null> =>
    cognitoLogin(getCognitoUserPool, { email, password }).then(() => loadUser());

  // load user on mount
  useEffect(() => {
    const loadUserAsync = async () => {
      await loadUser();
    };

    loadUserAsync();
  }, []); // Removed dependencies to ensure it runs only once on mount

  const value = useMemo(
    () => ({
      getRegistrationData,
      getCognitoUserPool,
      getCognitoUser,

      setGacUser: (user: IUser | null) => setGacUser(user),
      gacUser,
      getAuthToken,
      getUserRoles,
      loadUser,
      login,
      logout,
      // eslint-disable-next-line default-param-last
      ShowLoginModal: async (tab: "login" | "new-account" | "forgot-password" = "login") => {
        // set active tab in login modal
        setActiveLoginModalTab(tab);

        // open the modal
        setLoginOpen(true);
      },
      showingLoginModal: loginOpen,
    }),
    [gacUser, loginOpen, activeLoginModalTab, userRoles, authToken, tokenExpire, logout],
  );

  return (
    <AuthContext.Provider value={value}>
      {children}

      <LoginModal isOpen={loginOpen} setIsOpen={setLoginOpen} focusTab={activeLoginModalTab} setFocusTab={setActiveLoginModalTab} />
    </AuthContext.Provider>
  );
};
