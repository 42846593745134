import { PhoneNumberSchema } from "@components/FormControls/PhoneNumberEntry/schema";
import * as yup from "yup";

export const loginSchema = yup
  .object({
    email: yup.string().email("Must be a valid email address.").required("Required"),
    password: yup
      .string()
      .required("Required")
      .min(8, "Must be at least 8 characters")
      .matches(
        /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
        "Must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character",
      ),
  })
  .required();

export type LoginSchema = yup.InferType<typeof loginSchema>;

export const registerSchema = yup
  .object({
    firstName: yup.string().required("First name is a required field"),
    lastName: yup.string().required("Last name is a required field"),
    email: yup.string().email("Email must be a valid email").required("Email is a required field"),
    confirmEmail: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is a required field")
      .oneOf([yup.ref("email")], "Email Addresses must match"),
    password: yup
      .string()
      .required("Password is a required field")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character",
      ),
    passwordConfirmation: yup
      .string()
      .required("Password is a required field")
      .min(8, "Password must be at least 8 characters")
      .oneOf([yup.ref("password")], "Passwords must match"),
  })
  .required("Please confirm your password");

export type RegisterSchema = yup.InferType<typeof registerSchema>;

export const resendConfirmationSchema = yup.string().email("Email must be a valid email").required("Email is a required field");

export const verificationSchema = yup
  .object({
    email: yup.string().email().required("Email is a required field"),
    code: yup.string().trim().required("Verification Code is a required field"),
  })
  .required();

export type VerificationSchema = yup.InferType<typeof verificationSchema>;

export const forgotPasswordSchema = yup.object({
  email: yup.string().email("Email must be a valid email").required("Email is a required field"),
});

export type ForgotPasswordSchema = yup.InferType<typeof forgotPasswordSchema>;

export const resetPasswordSchema = yup.object({
  email: yup.string().required("Email is a required field"),
  code: yup.string().required("Verification Code is a required field"),
  password: yup
    .string()
    .required()
    .min(8)
    .matches(
      /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character",
    ),
  passwordConfirmation: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
});

export type ResetPasswordSchema = yup.InferType<typeof resetPasswordSchema>;

export const changePasswordSchema = yup.object({
  currentPassword: yup.string().required(),
  password: yup
    .string()
    .required("New password is required")
    .min(8, "New password must be 8 characters")
    .matches(
      /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character",
    ),
  passwordConfirmation: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
});

export type ChangePasswordSchema = yup.InferType<typeof changePasswordSchema>;

const baseProfileSchema = yup.object({
  firstName: yup.string().required("First name is a required field"),
  lastName: yup.string().required("Last name is a required field"),
  email: yup.string().email().required(),
  website: yup.string().url().nullable().notRequired(),
  streetName: yup.string().nullable().notRequired(),
  country: yup.string().nullable().notRequired(),
  state: yup.string().nullable().notRequired(),
  city: yup.string().nullable().notRequired(),
  timezone: yup.string().nullable().notRequired(),
  bio: yup.string().nullable().notRequired(),
  zip: yup.string().nullable().notRequired(),
  addressId: yup.string().nullable().optional().notRequired(),
  facebookLink: yup.string().nullable().optional().notRequired().url(),
  instagramLink: yup.string().nullable().optional().notRequired().url(),
  linkedInLink: yup.string().nullable().optional().notRequired().url(),
});

export const updateProfileSchema = baseProfileSchema.concat(PhoneNumberSchema);

export type UpdateProfileSchema = yup.InferType<typeof updateProfileSchema>;
