import { z } from "zod";

export const FollowUpDataSchema = z.object({
  name: z.string(),
  value: z.string().nullable(),
});

export const FollowUpDataInput = z.object({
  name: z.string().optional(),
  value: z.string(),
});
