import { CognitoUserAttribute, CognitoUserPool } from "amazon-cognito-identity-js";
import { RegisterSchema } from "@validators/auth";
import toast from "react-hot-toast";
import { RegistrationData } from "../../../types/User";

export const register = async (
  cognitoUserPool: CognitoUserPool,
  { email, password, firstName, lastName }: RegisterSchema,
  setRegistrationData: (data: RegistrationData) => void,
): Promise<boolean> => {
  const attributes: CognitoUserAttribute[] = [
    new CognitoUserAttribute({
      Name: "email",
      Value: email,
    }),
  ];

  cognitoUserPool.signUp(email, password, [], attributes, async (err, data) => {
    if (err) {
      let userMessage = `An error occured while changing your password. Please try again or contact us if this error persists. \n Error: ${err.message}`;

      switch (err.name) {
        case "CodeDeliveryFailureException": {
          userMessage = "Please check your email address.  We couldnt send it a verification code.";
          break;
        }
        case "UsernameExistsException": {
          userMessage = "An account already exists for this email. Please login or reset the password. ";
          break;
        }

        case "InvalidPasswordException": {
          userMessage = "Your password doesn't meet the policy.  See password policy requirements below.";
          break;
        }

        case "LimitExceededException": {
          userMessage = "Too many requests. Please wait a minute and try again.";
          break;
        }
        case "TooManyRequestsException": {
          userMessage = "Too many requests. Please wait a minute and try again.";
          break;
        }
        default: {
          break;
        }
      }

      toast.error(userMessage);
      return false;
    }

    if (!data) return false;

    setRegistrationData({ email, firstName, lastName });
    return true;
  });

  return false;
};
