import { z } from "zod";
import { AddressInputSchema } from "./address";
import { FollowUpDataInput } from "./followupData";

export const GenEventFollowupInputSchema = z.object({
  eventOccurrenceId: z.string(),
  followUpData: z.array(FollowUpDataInput),
});

export type GenEventFollowupInputType = z.infer<typeof GenEventFollowupInputSchema>;

export const JpeEventRegistrationOutputSchemaV1 = z.object({
  preferredMedia: z.array(z.string()),
  furtherDevelop: z.array(z.string()),
  inspiration: z.array(z.string()),
  artDescription: z.array(z.string()),
  generalQuestion: z.string().optional(),
});

export type JpeEventRegistrationOutputTypeV1 = z.infer<typeof JpeEventRegistrationOutputSchemaV1>;

export const EventRegistrationOutputSchema = z.object({
  eventOccurrenceId: z.string(),
  eventName: z.string(),
  eventSlug: z.string(),
  eventDate: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  attendeeNote: z.string().optional(),
  type: z.string(),
  numberOfAttendees: z.number(),
  maxAttendees: z.number(),
  locationHtml: z.string().optional(),
  feedbackSubmitted: z.boolean(),

  jpeData: z.optional(JpeEventRegistrationOutputSchemaV1),
});

export type EventRegistrationOutputType = z.infer<typeof EventRegistrationOutputSchema>;

export const BaseEventRegistrationInputSchema = z.object({
  eventOccurrenceId: z.string(),
  eventName: z.string(),
  eventSlug: z.string(),
  nameUpdated: z.boolean(),
  firstName: z.string(),
  lastName: z.string(),
  attendeeNote: z.string(),
  type: z.string(),
  numberOfAttendees: z.number().default(1),
  maxAttendees: z.number().default(-1),
  eventDate: z.string(),
  locationHtml: z.string().optional(),
});

export type BaseEventRegistrationInputType = z.infer<typeof BaseEventRegistrationInputSchema>;

export const JpeEventRegistrationInputSchemaV1 = z.object({
  baseEventData: BaseEventRegistrationInputSchema,

  preferredMedia: z.array(z.string()),
  furtherDevelop: z.array(z.string()),
  inspiration: z.array(z.string()),
  artDescription: z.array(z.string()),
  generalQuestion: z.string().optional(),
});

export type JpeEventRegistrationInputTypeV1 = z.infer<typeof JpeEventRegistrationInputSchemaV1>;

export const EventRegistrationInputSchema = z.discriminatedUnion("eventRegistrationType", [
  z.object({ eventRegistrationType: z.literal("base"), data: BaseEventRegistrationInputSchema }),
  z.object({ eventRegistrationType: z.literal("jpe"), data: JpeEventRegistrationInputSchemaV1 }),
]);

export type EventRegistrationInputType = z.infer<typeof EventRegistrationInputSchema>;

export const JpeFollowupInputSchemaV1 = z.object({
  eventOccurrenceId: z.string(),
  mailingAddress: z.optional(AddressInputSchema),

  brandsUsedLastYear: z.array(z.string()),
  attendedGoldenEventBefore: z.boolean(),
  describeExpereince: z.array(z.string()),
  attendFutureEvents: z.string(),
  inspiredToPurchase: z.string(),
  recomendGolden: z.number(),
  receiveComsFromArtist: z.boolean(),
  samplePreference: z.array(z.string()),
});

export type JpeFollowupInputTypeV1 = z.infer<typeof JpeFollowupInputSchemaV1>;
