import { useState, useEffect } from "react";

export function useSyncedSessionStorage<T>(key: string, initialValue: T): [T, (newValue: T) => void, () => void] {
  const [value, setValue] = useState<T>(() => {
    const storedValue = typeof window !== "undefined" ? sessionStorage.getItem(key) : null;

    if (storedValue) {
      try {
        return JSON.parse(storedValue) as T;
      } catch (error) {
        console.error(`Error parsing sessionStorage key "${key}":`, error);
        return initialValue;
      }
    }
    return initialValue;
  });

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.storageArea === sessionStorage && event.key === key) {
        try {
          setValue(event.newValue ? (JSON.parse(event.newValue) as T) : initialValue);
        } catch (error) {
          console.error(`Error parsing updated sessionStorage key "${key}":`, error);
        }
      }
    };

    if (typeof window !== "undefined") window.addEventListener("storage", handleStorageChange);
    return () => {
      if (typeof window !== "undefined") window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, initialValue]);

  const setStoredValue = (newValue: T) => {
    setValue(newValue);
    if (typeof window !== "undefined") sessionStorage.setItem(key, JSON.stringify(newValue));
  };

  const clear = () => {
    setValue(initialValue);
    if (typeof window !== "undefined") sessionStorage.removeItem(key);

    // Trigger 'storage' event to notify other tabs
    if (typeof window !== "undefined") window.dispatchEvent(new StorageEvent("storage", { key, newValue: null }));
  };

  return [value, setStoredValue, clear];
}
